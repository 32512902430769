<template>
    <div>
        <div class="list clearfloat">
            <ul>
                <li>
                    <img class="banner" src="../../assets/img/banner.png" alt="">
                    <p>哈哈哈哈哈</p>
                    <span>¥  36.00</span>
                    <b>原价：140.00</b>
                    <img class="add" src="../../assets/icon/add.png" alt="">
                </li>
                <li>
                    <img class="banner" src="../../assets/img/banner.png" alt="">
                    <p>哈哈哈哈哈</p>
                    <span>¥  36.00</span>
                    <b>原价：140.00</b>
                    <img class="add" src="../../assets/icon/add.png" alt="">
                </li>
                <li>
                    <img class="banner" src="../../assets/img/banner.png" alt="">
                    <p>哈哈哈哈哈</p>
                    <span>¥  36.00</span>
                    <b>原价：140.00</b>
                    <img class="add" src="../../assets/icon/add.png" alt="">
                </li>
                <li>
                    <img class="banner" src="../../assets/img/banner.png" alt="">
                    <p>哈哈哈哈哈</p>
                    <span>¥  36.00</span>
                    <b>原价：140.00</b>
                    <img class="add" src="../../assets/icon/add.png" alt="">
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
.list { 
    padding: 16px;
    li { width: 168px; height: 263px; float: left;background: #fff; margin-bottom: 8px; position: relative; 
        img.banner { width: 168px; height: 168px;}
        p { font-size: 14px; color: #2d2d2d; padding: 10px; line-height: 14px;}
        span { font-size: 20px; color: #FB5151; line-height: 28px; display: block; padding: 0 10px; height: 28px;} 
        img.add { width: 20px; height: 20px; position: absolute; right: 10px; bottom: 10px} 
        b { color: #c2c2c2; font-size: 14px; line-height: 17px; padding: 0 10px; text-decoration: line-through; font-weight: normal; margin-top: 3px; display: block;} 
    }
    li:nth-child(odd) { margin-right: 7px;}  
} 
</style>


