<template>
    <div class="bg">
        <div class="tabs">
            <van-tabs v-model="active" animated :swipeable='true'>
                <van-tab title="医院中药">
                    <div class="top_fixed">
                        <van-search
                            v-model="value"
                            placeholder="请输入搜索关键词"
                            input-align = "center"
                            left-icon = 'none'
                            show-action
                        >
                        <template #action>
                            <div @click="onSearch">搜索</div>
                        </template>
                        </van-search>
                        <div class="choseitem">
                            <div class="right" @click="isShowAlert=!isShowAlert">
                                <img src="../../assets/icon/fenlei.png" alt="">
                                <p>分类</p>
                            </div>
                        </div>
                    </div>
                    <list></list>
                </van-tab>
                <van-tab title="名医中药">
                    <div class="top_fixed">
                        <van-search
                            v-model="value"
                            placeholder="请输入搜索关键词"
                            input-align = "center"
                            left-icon = 'none'
                            show-action
                        >
                        <template #action>
                            <div @click="onSearch">搜索</div>
                        </template>
                        </van-search>
                        <div class="choseitem">
                            <div class="right" @click="isShowAlert=!isShowAlert">
                                <img src="../../assets/icon/fenlei.png" alt="">
                                <p>分类</p>
                            </div>
                        </div>
                    </div>
                    <list></list>
                </van-tab>
                <van-tab title="" disabled></van-tab>
                <van-tab title="" disabled></van-tab>
            </van-tabs>
            <screen :show="isShowAlert" :option="option" @changeShow="isShowAlert = false"></screen>
        </div>
    </div>
</template>

<script>
import list from '@/components/common/list'
import screen from '@/components/common/screen'
export default {
    name:'chineseMedicine',
    components:{
        list,screen
    },
    data(){
        return {
            active: 0,
            value1: "a",
            isShowAlert: false,
            option: [
                { text: "0~100", value: 0 },
                { text: "100", value: 1 },
                { text: "100", value: 2 },
                { text: "100", value: 2 },
            ],
        }
    },
    methods:{
        onSearch(){},
    }
}
</script>

<style lang="less" scoped>
/deep/.van-tabs__nav { background: #fff; }
/deep/.tabs .van-tab .van-tab__text { font-size: 18px !important; color: #444; font-weight: bold;} 
/deep/.van-tabs__line { background: #1DAEFC; width: 72px; height: 6px;} 
/deep/.van-search__content { border-radius: 18px;}
/deep/.van-search__action { padding: 0 20px; color: #1DAEFC} 
.bg { width: 100vw; height: auto; background: #f5f5f5;
    .choseitem {
        background: #fff;
        height: 52px;
        line-height: 52px;
        .left { float: left;
        img { width: 20px; height: 20px; display: block; float: left; margin: 16px 8px 16px 17px; }
        p { font-size: 14px; height: 52px; line-height: 52px; color: #C2C2C2; float: left;}  
        } 
        .right { float: right; margin-right: 17px;
        img { width: 20px; height: 20px; display: block; float: left; margin: 16px 8px 16px 17px; }
        p { font-size: 14px; height: 52px; line-height: 52px; color: #C2C2C2; float: left;}  
        } 
    }
    .top_fixed { background-color: #fff; width: 100%; height: auto;} 
    .tabs {
        .tabs_item { 
            padding: 16px; background: #fff;
            li { font-size: 18px; color: #444; float: left; font-weight: bold; margin-right: 24px;}
        } 
        .all { text-align: right; font-size: 14px; color: #2d2d2d; padding: 0 16px 24px 0;} 
    }
}
</style>