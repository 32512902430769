<template>
    <div>
        <van-overlay :show="show">
            <div class="wrapper">
                <div class="alert">
                    <div class="item">
                        <p>价格</p>
                        <ul class="clearfloat">
                            <li :class="value1==item.value?'on':''" v-for="(item,index) in option" :key="index">{{item.text}}</li>
                        </ul>
                    </div>
                    <div class="button">
                        <button>重新选择</button>
                        <button class="on" @click="promise">确定</button>
                    </div>

                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    props:{
        show:{
            default: false,
            type: Boolean
        },
        option:{
            default: new Array(),
            type: Array
        }
    },
    data(){
        return{

        }
    },
    methods:{
        //确定
        promise(){
            this.show = false
            this.$emit('changeShow')
        },
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.alert { 
    background: #FFF; width: 100vw; height: 100vh; border-top: 1px solid #EFEFEF; z-index: 9;
    .item {
        min-height: calc(100vh - 150px);
        p { font-size: 14px; color: #2D2D2D; height: 52px; line-height: 52px; padding: 0 16px;}
        ul { padding: 0 16px;
        li { width: auto; height: 44px; border-radius: 9px; border: 1px solid #C2C2C2; padding: 0 20px; line-height: 42px; font-size: 14px; color:#444444; float: left; margin-bottom: 10px; margin-right: 10px;}
        li.on { border-color: #1DAEFC; color: #1DAEFC;}  
        }
    }
    .button {
        button { width: 343px; height: 44px; border-radius: 9px; border: 1px solid #C2C2C2; padding: 0 20px; line-height: 42px; font-size: 14px; color:#444444; margin: 16px auto; background: #fff; display: block;}
        button.on { color: #fff; border-color: #1DAEFC; background: #1DAEFC;}  
    } 
} 
</style>